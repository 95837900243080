import React from "react"
import tw from "twin.macro"

// Components
import Layout from "@components/layout"
import Seo from "@components/seo"
import Container from "@components/container"
import Section from "@components/section"
import Anchor from "@components/anchor"

import { Heading1, redButton } from "@styles/ui"

// Assets
import logo from "@images/logos/logo-colourful-slight-transparency.svg"

const ServerError = ({ location }) => {
  return (
    <Layout
      location={location}
      mainBg="seashell"
      copyright={
        <>
          © 2024 Jazz Pharmaceuticals plc or its subsidiaries{" "}
          <br tw="block md:hidden" />
          US-XYW-2200207 Rev0824
        </>
      }
    >
      <Seo
        title="Page not found | Xywav HCP (Final)"
        meta={["robots", "noindex"]}
        location={location}
      />
      <Section
        addedStyles={tw`heroBgFade pt-0 relative overflow-hidden pb-[221px] md:(pb-[193px] bg-none) xl:pt-0`}
      >
        <div>
          <img
            src={logo}
            tw="absolute right-[-50px] bottom-[-75px] w-[340px] h-[220px] md:(w-[342.27px] h-[221px] top-[100px] right-[-51px]) xl:(w-[920px] h-[594px] right-[-103px] top-[unset])"
            alt=""
          />
        </div>
        <Container>
          <h1 css={[Heading1, tw`xl:text-[54px]`]}>Page not found</h1>
          <p tw="mt-5 text-2xl pr-2 xl:text-[34px]">
            Sorry, the page you requested was not found.
          </p>
          <Anchor
            link="/"
            addedStyles={[
              redButton,
              tw`relative z-10 max-w-[164px] h-[40px] mt-[30px] text-xs xl:(h-[64px] min-w-[246px] text-3xl mt-[50px])`,
            ]}
          >
            Back to home
          </Anchor>
        </Container>
      </Section>
    </Layout>
  )
}

export default ServerError
